@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

.topcontent{
    color: white;
}

.topContainer{
    background: url('./../../assest/develop.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.5);
}
.topcontent_container{
    position: absolute;
    top: 260px;
    left: 1050px;
}
.topcontent_container p{
    letter-spacing: 1px;
    text-align: left;
    padding-left: 3px;
    padding-top: 20px;
}
.topcontent_container a button{
    margin-bottom: 30px;
    text-align: left;
    margin-left: 5px;
    outline: none;
}
.a{
    color: white;
    background-color: orangered;
    font-size: 14px;
    padding: 10px 10px;
    font-family: "Montserrat",sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 10px;
    border-radius: 6px
}
.a:hover{
    color: black;
    background-color: white;
    font-weight: bold;
}
.b{
    color: white;
    border: 5xp solid white !important;
    background-color: transparent;
    font-size: 14px;
    padding: 10px 30px;
    font-family: "Montserrat",sans-serif;
    font-weight: 300;
    cursor: pointer;
}
.b:hover{
    color: black;
    background-color: white;
    font-weight: bold;
}

