.lightbg{
    background-color: black
}
.darkbg{
    background-color: goldenrod;
}

.fax{
    height: 220px;
    width: 50% !important;
    border: 2px solid white;
    text-align: center;
    margin-bottom: 200px;
    margin-top: 230px;
}
.fax h6{
    color: white;
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px
}
.fax h1{
    color: white;
    margin-top: 30px;
    font-weight: bold;
    font-size: 18px
}
.fax span{
    color: orangered;
    margin-left: 10px;
}
.fax a{
margin-top:50px !important;
margin-left: 30px;
font-size: 30px;
border-radius: 10px;
}